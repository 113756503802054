@import '../../../assets/styles/media';

.dropdownRow__list {
  width: 100%;
  margin: 0;
  list-style-type: none;

  @include desktop-l {
    padding-left: 1rem;
  }
}

.anamnesisDropdown__list {
  width: 100%;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @include mobile-xl {
    padding: 1rem 0.5rem 0;
  }
}
