@import '../../../../../../assets/styles/media';

.anamnesisSummary {
  padding: 0 1.69rem;
  height: calc(100% - 1rem);
  overflow-y: hidden;
  display: flex;
  flex-direction: column;

  @include desktop-xl {
    padding-left: 0;
  }

  @include tablet-xxl {
    padding-right: 0.69rem;
  }

  @include mobile-xl {
    padding-right: 0;
  }
}

.anamnesisSummary__icon {
  margin-right: 1.19rem;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 5px;
  border: 2px solid var(--color-darkGreen);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.anamnesisSummary__img {
  width: 2.25rem;
  height: 2.25rem;
  object-position: center;
  object-fit: cover;
}

.anamnesisSummary__info {
  font-size: 1rem;

  &.firstChild {
    padding-left: 0;
  }

  @include tablet-xl {
    padding-left: 0;
  }
}

.anamnesisSummary__header {
  display: flex;
  align-items: center;
}

.anamnesis_table {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.anamnesis_table_body {
  padding: 0 1rem;
  height: calc(100% - 3.5rem);
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 0.5rem;
    background-color: var(--color-scrollGrayBackground);
    border-radius: 0.34rem;
    overflow: hidden;
    padding-left: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-scrollBar);
    border-radius: 0.34rem;
  }
}
