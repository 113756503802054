@import 'src/assets/styles/media';

.heading {
  padding: 0.75rem 1rem 0.75rem 2.25rem;
  width: calc(100% - 23rem);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  overflow: hidden;
}

.heading__present {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.heading__title {
  display: flex;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;

  @include tablet-xl {
    font-size: 1.8rem;
  }
}

.heading__subtitle {
  font-size: 1.12rem;
  font-weight: 600;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;

  &:first-letter {
    text-transform: uppercase;
  }
  @include tablet-xl {
    font-size: 1.1rem;
  }
}

.heading__picture {
  height: 10rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  right: 122px;
  top: 38px;

  &.ranking {
    top: 65px;
  }
}

.heading__img {
  max-width: 100%;
  max-height: 100%;
  object-position: center;

  @include tablet-xxl {
    max-height: 8.5rem;
  }

  @include tablet-xl {
    max-height: 7.5rem;
  }
}
.heading__ranking_helpers {
  font-size: 0.92rem;
  line-height: 1.3rem;
  text-transform: capitalize;
  margin-top: 5px;
}
