@import "src/assets/styles/media";

.table {
}

.table__header {
  margin-bottom: 0.5rem;
  width: 100%;
  min-height: 3rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.375rem;
}

.table__headerCell {
  padding-left: 1rem;
  padding-right: 0.8rem;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 1.15rem;
  font-weight: 700;
}

.table__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.table__row {
  margin-bottom: 0.5rem;
  padding: 0;
  width: 100%;
  min-height: 25px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background-color: var(--color-gray5);

  @include tablet-xl {
    flex-direction: column;
  }

  @include mobile-xl {
    margin-bottom: 1.2rem;
  }
}

.table__cell {
  padding-left: 1.25rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.69rem;
  color: var(--color-fontBlack);
  border-left: 2px solid var(--color-darkGreen);

  @include tablet-xl {
    width: 100%;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-gray10);

    &:last-child {
      border-bottom: none;
    }
  }

  &.pollingItem{
    color: var(--color-polling);
  }
}

.table__title {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  padding-right: 4rem;
  max-width: 70%;
}

.table__info {
  @include tablet-xl {
    text-align: end;
    display: flex;
    justify-content: space-between;
  }
}
