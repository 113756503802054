@import "../../../assets/styles/media";

.category__item {
  margin-bottom: 2.125rem;
  // column-width: 200px;
  // column-fill: auto;
  @include mobile-xl{
    margin-bottom: 0.5rem;
  }
  @include tablet-xl {
    margin-bottom: 1rem;
  }
}

.dropdownRow__list {
  width: 100%;
  margin: 0;
  // padding: 1.75rem 0.94rem 0 1.75rem;
  list-style-type: none;
  // column-width: 200px;
  // display: flex;
  // flex-wrap: wrap;

  @include desktop-l {
    padding-left: 1rem;
  }
  @include mobile-xl{
    padding: 0.5rem;
  }
}

.anamnesisDropdown__list {
  width: 100%;
  margin: 0;
  padding:1rem 0.94rem 0 1.75rem;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @include tablet-xl {
    padding: 0rem 0.5rem 0;
  }
}

.category__button {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  margin-bottom: 1rem;

  @include tablet-xl {
    margin-bottom: 0.5rem;
  }

  &.active .category__arrow {
    transform: rotate(90deg);
  }
}

.category__title {
  margin-right: 0.87rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: var(--color-fontBlack);
  text-align: left;
  @include mobile-xl{
    font-size: 0.9rem;
    line-height: 1rem;
  }
}

.category__arrow {
  width: 0.94rem;
  height: 0.94rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s;
  margin-right: 0.9rem;
}

.category__svg {
  width: 100%;
  height: 100%;
}
