.comment {
  font-weight: 400;
  margin-top: 0.3rem;
}
.questionSet1Summary {
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0.69rem;
    background-color: var(--color-scrollGrayBackground);
    border-radius: 0.34rem;
    overflow: hidden;
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--color-scrollBar);
    border-radius: 0.34rem;
  }
}

.questionSet1Summary__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.questionSet1List {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  list-style: none;
  border-left: 2px solid var(--color-darkGreen);
  margin: 1rem 0.5rem;
  padding: 0.5rem 1.25rem;
  background-color: var(--color-scrollGrayBackground);
  color: var(--color-fontBlack);
  &.pollingItem {
    color: var(--color-polling);
  }
}
