@import "src/assets/styles/media";

.termsPopup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.termsPopup__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45vw;
  height: 90vh;
  background-color: var(--color-white);
  border: 8px solid var(--color-darkblue);
  border-radius: 0.75rem;
  padding-top: 2rem;
  padding-bottom: 0.5rem;
  position: relative;

    @include desktop-l {
      width: 55vw;
    border: 6px solid var(--color-darkblue);
  }

  @include mobile-xl {
    width: 95vw;
    height: 80vh;
  }
}

.termsPopup__content {
  width: 100%;
  height: 100%;
  padding: 1rem 2rem;

  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @include mobile-xl {
    padding: 1rem;
  }

  &::-webkit-scrollbar {
    width: 0.69rem;
    background-color: var(--color-scrollBackground);
    border-radius: 0.34rem;
    overflow: hidden;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-scrollBar);
    border-radius: 0.34rem;
  }
}

.termsPopup__img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
}

.termsPopupClose {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(128, 128, 128, 0.568);
}

.termsPopup__close {
  position: absolute;
  top: -1.845rem;
  right: -1.845rem;
  padding: 0.81rem;
  width: 3.69rem;
  height: 3.69rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--color-darkblue);
  cursor: pointer;
  z-index: 15;

  @include desktop-l {
    top: -1.47rem;
    right: -1.47rem;
    width: 2.69rem;
    height: 2.69rem;
  }

  @include mobile-xl {
    top: -1.2rem;
    right: -1.3rem;
  }
}

.termsPopup__closeSvg {
  fill: var(--color-white);
  width: 100%;
  height: 100%;
  object-position: center;
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}


.logo {
  width: 9rem;
  height: 4.5rem;
  object-position: center;

  // @include tablet-xxl {
  //   width: 7.74rem;
  //   height: 3.9rem;
  // }
}

.title {
  text-align: center;
  margin-bottom: 1.5rem;
  text-decoration: underline;
  font-size: 1.2rem;
  margin-right: 0.25rem;
  font-weight: bold;

  @include mobile-xl {
    font-size: 1.4rem;
  }
}

.sectionTitle {
  margin-bottom: 1rem;
  text-decoration: underline;
  font-size: 1.1rem;
  @include mobile-xl {
    font-size: 1.2rem;
  }
  // font-weight: bold;
}

.paragraph {
  font-size: 1rem;
  text-decoration: none;
  line-height: 1.5;
  @include mobile-xl {
    font-size: 1.1rem;
  }
}

.paragraph:not(:last-child) {
  margin-bottom: 1rem;
}

.section:not(:first-child) {
  margin-top: 2rem;
}