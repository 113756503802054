@import 'src/assets/styles/media';

.menu {
  overflow-y: hidden;
  padding-left: 1rem;
  height: 100%;
}

.headerLogo {
  height: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerLogo_png {
  width: 9rem;
}

.nav_container {
  height: calc(100% - 7.5rem);
}

.tiny_url {
  font-size: 0.95rem;
  font-weight: 700;
  line-height: 1.25rem;
  margin-top: 0.5rem;
}

.nav {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: var(--color-white);
  box-shadow: 0px 4px 25px rgba(170, 154, 130, 0.5);
  border-radius: 10px 0px 0px 10px;
  flex-shrink: 0;
  padding: 2rem 0 0 1.429rem;
  height: calc(100% - 5rem);
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
}
.qr_code_holder {
  position: absolute;
  width: calc(100% - 1.429rem);
  bottom: 5.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(
    270deg,
    rgba(192, 221, 198, 0.35) -7.24%,
    rgba(79, 177, 194, 0.35) 126.92%
  );
  padding: 2rem;
  height: 15rem;
  @include desktop-xl {
    padding: 1rem;
    height: 13.5rem;
  }

  @include tablet-xxl {
    padding: 1rem;
    height: 13.5rem;
  }
  .qr_code_header {
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: center;
    margin-bottom: 0.5rem;
    font-weight: 400;
    word-break: break-word;
  }
}
.nav_list_holder {
  padding-right: 0.75rem;
  height: calc(100% - 22rem);
  @include desktop-xl {
    height: calc(100% - 19.75rem);
  }

  @include tablet-xxl {
    height: calc(100% - 19.75rem);
  }
  &.noQrCode {
    height: calc(100% - 5rem);
    @include desktop-xl {
      height: calc(100% - 5rem);
    }

    @include tablet-xxl {
      height: calc(100% - 8rem);
    }
  }
}
.nav__list {
  height: 100%;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  padding-right: 0.5rem;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0.4rem;
    background-color: var(--color-gray5);
    border-radius: 0.34rem;
    overflow: hidden;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-darkGreen);
    border-radius: 0.34rem;
  }
}

.nav__item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.45rem;
  padding-right: 0.85rem;
  font-size: 0.8572rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: 0em;
  text-align: left;
}

.nav__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav__svg {
  filter: invert(29%) sepia(0%) saturate(0%) hue-rotate(157deg) brightness(100%) contrast(91%);
  width: 1.7rem;
  height: 1.7rem;
  object-position: center;
  overflow: hidden;

  // @include mobile-xl {
  //   width: 1.5rem;
  //   height: 1.5rem;
  // }

  img {
    width: 1.2rem;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.nav__prop {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  transition: 0.3s;

  &.collapsed {
    margin-left: 1.5rem;
  }

  // @include tablet-xxl {
  //   flex-direction: column;
  //   justify-content: center;
  //   margin: 0;
  // }
}

.nav__text {
  margin-left: 0.89rem;

  // @include tablet-xxl {
  //   text-align: center;
  //   margin: 1rem 0 0;
  //   font-size: 1rem;
  // }

  // @include mobile-xl {
  //   margin-top: 0.7rem;
  // }
}

.nav__item.active {
  color: var(--color-highlightGreen);
  font-family: Poppins;
  font-size: 0.8572rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0em;
  text-align: left;
  background: var(--color-linearGreen);
}

.menu__pic {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: 18rem;
  position: absolute;
  bottom: 0;
}

.menu__img {
  max-width: 100%;
  max-height: 11rem;
  object-position: bottom;
  object-fit: contain;
}
.menu_green_bubble {
  position: absolute;
  bottom: 0;
  right: 1rem;
  .green_bubble_image {
    height: 5.5rem;
  }
}

.finished{
  cursor: pointer;
  color: var(--color-nav-passed);
}
.clickable {
  cursor: pointer !important;
}

.activeIcon {
  filter: invert(61%) sepia(54%) saturate(425%) hue-rotate(141deg) brightness(94%) contrast(83%);
}

.navPassed {
  filter: invert(89%) sepia(14%) saturate(177%) hue-rotate(191deg) brightness(86%) contrast(87%);
}
