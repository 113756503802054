@import 'src/assets/styles/media';

$header-height: 48px;
$header-MobileHeight: 5.77rem;
$medqp-background: #ffc8a8;

.wrapperOuter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(var(--vh, 1vh) * 100 - 1px);
  min-height: 680px;
  overflow: hidden; 
  padding: 1rem 2rem;
  background-color: $medqp-background;
  background: url('../../assets/nImages/background_cover.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  // @include tablet-xl {
  //   min-height: max-content;
  //   height: 100%;
  // }

  // @include mobile-xl {
  //   height: 100%;
  // }
}

.wrapperHeader {
  display: flex;
  align-items: center;
  height: $header-height;
  flex-shrink: 0;
  margin-bottom: 1rem;

  // @include tablet-xxl {
  //   height: $header-MobileHeight;
  // }
}

.wrapperInner {
  display: flex;
  height: calc(100% - #{$header-height} - 1rem);
  flex-grow: 1;

  &.welcomeWrapper{
    height: 100%;
  }

  // @include tablet-xxl {
  //   flex-direction: column;
  //   justify-content: space-between;
  //   height: calc(100% - #{$header-MobileHeight});
  // }

  // @include mobile-xl {
  //   height: auto;
  // }
}

.wrapperMenu {
  transition: 0.3s;
  width: auto;
  height: 100%;
  display: flex;
  flex: 0 0 auto;
  white-space: nowrap;

  // @include tablet-xxl {
  //   margin-bottom: 1rem;
  //   width: 100%;
  // }

  // @include mobile-xl {
  //   margin-bottom: 0.6rem;
  // }
}

.wrapper {
  height: 100%;
  flex: 1 1 auto;
  transition: 0.3s;
  display: flex;

  @include tablet-xxl {
    width: 100%;
    min-height: calc(100% - 6rem);
    padding-bottom: 0.5rem;
  }

  //@include mobile-xl {
  //  padding-bottom: 0;
  //}
}

.wrapperRoutes {
  height: 100%;
  width: 100%;
}

.eventBackgroundContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  .event_background {
    max-width: 100%;
    max-height: 100%;
    display: block;
  }
}