@import '../../../../assets/styles/media';

.mcGroupSectionView {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.mcGroupList {
  margin: 0;
  list-style-type: none;
  border-top: 0;
  padding: 1rem;
  word-break: break-word;
  @include mobile-xl {
    padding: 0.5rem;
  }
}

.mcGroupContainer {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .questionSet1__selectAll {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1rem;
    @include mobile-xl {
      margin-bottom: 0.25rem;
    }
  }

  .questionSet1__selectAll_groups {
    margin-right: 2.5rem;
    @include mobile-xl {
      margin-right: 0rem;
    }
  }

  .questionSet1__selectInner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.questionSet1__selectAll {
  margin-bottom: 0.5rem;
}

.questionSet1__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  height: calc(100% - 1rem);
  overflow-y: auto;
  display: flex;
  flex-flow: wrap;
  flex-direction: column;
  flex-shrink: inherit;
  align-content: flex-start;
  word-break: break-word;
  // column-width: 300px;
  column-fill: auto;

  &.treatment {
    height: calc(100% - 10.5rem);

    @include mobile-xl {
      height: calc(100% - 11.5rem);
    }
  }

  @include mobile-xl {
    overflow-x: hidden;
    display: block;
    padding-right: 0.5rem;
  }
}

.questionSet1__selectAll {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}

.questionSet1__selectInner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.treatmentWrite {
  margin: 1rem 0;
  border: 2px solid var(--color-darkGreen);
  border-radius: 5px;
}

.treatmentWrite__header {
  padding: 0.75rem;
  background: linear-gradient(180deg, #c2dec4 0%, #a3d7e9 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.treatmentWrite__title {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: var(--color-fontBlack);

  .restCharacters {
    font-weight: 500;
  }
  @include mobile-xl {
    font-size: 0.85rem;
    line-height: 1rem;
  }
}

.treatmentWrite__body {
  // margin-top: 1.37rem;
}

.treatmentWrite__input {
  width: 100%;
  border: none;
  padding: 0.5rem 0.62rem 0.5rem 2.12rem;
  background-color: transparent;
  font-size: 1rem;
  outline: none;
  height: 4rem;
  box-sizing: border-box;
  resize: none;
}

.treatmentWrite__input:disabled {
  background-color: var(--color-gray5);
}

.restCharacters {
  font-size: 0.8rem;
}
