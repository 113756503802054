@import 'src/assets/styles/media';

.interviewPanel {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @include desktop-xxl {
    // padding: 0 3.37rem;
  }

  @include desktop-xl {
    // padding: 0 2.5rem;
  }

  @include tablet-xxl {
    // padding: 0 1.2rem 1.5rem;
  }

  @include mobile-xl {
    min-height: calc(var(--vh, 1vh) * 100 - 13.12rem);
    height: 100%;
    padding: 0 0.5rem;
  }
}

.interview {
  height: calc(100% - 5rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @include tablet-xxl {
    height: calc(100% - 4.5rem);
  }
}

.interviewHeading {
  min-height: 7.5rem;
  background: linear-gradient(180deg, #c2dec4 0%, #a3d7e9 100%);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  &.interviewRanking {
    min-height: 10.5rem;
  }
}

.interviewWrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 7.5rem);
  &.interviewRanking {
    height: calc(100% - 10.5rem);
  }
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(170, 154, 130, 0.8);
  border-radius: 0px 0px 10px 0px;
  z-index: 0;
}

.interviewPageContainer {
  display: flex;
  flex-direction: column;
  height: calc(100% - 1rem);
  background: url(../../assets/nImages/paper_texture.svg) repeat;
  background-size: cover;
  margin: 0.5rem;
}

.physicalExamInterview {
  .interviewPages {
    border: 0 !important;
    padding: 0.5rem 0 !important;
  }
}

.otherInterview {
  padding: 0.75rem;
  .interviewPages {
    padding: 0.75rem 1.5rem 1rem 1.5rem;
    border: 3.5px solid var(--color-highlightGreen);
  }
}

.interviewPages {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  overflow-y: auto;
  .interviewPageContainer{
    &.skip_chapter_active {
      height: calc(100% - 2rem);
    }
  }
}

.interview__shift {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 0.5rem;

  @include mobile-xl {
    align-items: flex-end;
  }
}
.interview_action_group {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 100%;
}

.interview__modify {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  height: 100%;
}

.modifyButton {
  margin-right: 2rem;
}

.pollInfoGroup {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 1rem;
  color: var(--color-fontBlack);
  font-weight: 700;
  margin: 0 2rem 0 1rem;
  .pollTimer {
    display: flex;
    flex-direction: row;
    font-size: 0.9rem;
    margin-bottom: 2px;
    justify-content: center;
    flex-grow: 1;    
    margin-bottom: 5px;
  }
  // .pollTimerClosed {
  //   padding-right: 1rem;
  // }
  .pollCountHolder {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .pollTimerHolder {
      text-align: center;
    }
    .pollCount {
      width: auto;
      display: flex;
      align-items: flex-end;
      flex-grow: 1;
      margin-right: 1rem;
      font-weight: 700;
      height: 100%;
      padding-bottom: 1rem;
    }
    .closePollButton {
      display: flex;
    }
  }
}

.pollOpenInfo {
  margin-right: 1rem;
}

.skip_text {
    cursor: pointer;
    padding-left: 0.7rem;
    height: 1.5rem;
    font-size: 1.125rem;
    @include mobile-xl {
      font-size: 1rem;
    }
  }
