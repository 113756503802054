:root {
  --color-darkblue: #1D2F56;
  --color-link: rgb(74,134,232);
  --color-lightblue: rgb(17, 202, 188);
  --color-lightblue--02: rgba(17, 202, 188, 0.2);
  --color-lightgreen: rgb(92, 189, 181);
  --color-lightgreen1: rgb(17, 202, 188);
  --color-lightgreen1--02: rgba(17, 202, 188, 0.2);
  --color-lightgreen3: rgba(149, 219, 201);
  --color-gray: rgb(181, 181, 181);
  --color-lightgray: rgb(67, 78, 84);
  --color-gray1: rgb(102, 102, 102,);
  --color-gray2: rgb(64, 64, 64);
  --color-gray3: rgb(243, 243, 243);
  --color-gray4: rgb(67, 78, 84);
  --color-gray5: rgb(229, 229, 229);
  --color-gray6: rgb(233, 233, 233);
  --color-gray7: rgb(183, 183, 183);
  --color-gray8: rgb(191, 191, 191);
  --color-gray9: rgb(196, 196, 196);
  --color-gray10: rgb(201, 201, 201);
  --color-white: rgb(255, 255, 255);
  --color-white2: rgb(251, 251, 251);
  --color-black: rgb(0, 0, 0);
  --color-red: rgb(215, 43, 52);
  --menu-width: 20rem;

  --color-buttongrey:#61667E;
  --color-linearGreen: linear-gradient(270deg, rgba(192, 221, 198, 0.35) -7.24%, rgba(79, 177, 194, 0.35) 126.92%);
  --color-highlightGreen: rgba(79, 177, 194, 1);
  --color-app-font: #4f4f4f;
  --color-nav-passed: rgba(187, 190, 203, 1);
  --color-scrollBar:rgba(79, 177, 194, 1);
  --color-scrollBackground: rgba(211, 239, 237, 1);
  --color-scrollGrayBackground: #dadada;
  --color-fontBlack : #4f4f4f;
  --color-polling: rgba(255, 122, 0, 1);
  --color-lightOrange: rgba(245, 174, 107, 1);
  --color-darkGreen: rgba(79, 177, 194, 1);
  --color-pollVerdict: #C0F2FA;
}
