.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.25rem;
  min-width: 8.62rem;
  height: 2.62rem;
  color: var(--color-white);
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}

.variant_primary {
  background-color: var(--color-darkblue);
}

.variant_primaryDark {
  background-color: #081738;
}

.variant_light {
  background-color: var(--color-darkGreen);
}

.variant_grey {
  background-color: var(--color-buttongrey);
}

.variant_transparent {
  background-color: transparent;
  border: 2px solid var(--color-darkblue);
  color: var(--color-darkblue);
}

.variant_danger {
  background-color: var(--color-red);
}

.variant_polling {
  background-color: rgba(255, 122, 0, 1);
}

.root:disabled {
  background-color: var(--color-gray);
  color: var(--color-white);
  border: none;
  cursor: default;
}

.variant_transparent:disabled {
  background-color: transparent;
  border: 2px solid var(--color-darkblue);
}

.buttonArrow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerLogo__icon {
  width: 15px;
  height: 15px;
  object-position: center;
}

.buttonText {
  display: inline-block;
  margin: 0 10px;
}
