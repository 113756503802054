@import 'src/assets/styles/media';

.zoomImage {
  width: 85vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
}

.zoomImage__pic {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: auto; 
  scrollbar-color: #aaa #eee;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
  @include tablet-xl {
    justify-content: flex-start;
    // align-items: flex-start;
    padding-right: 10px;
  }
  @include mobile-xl {
    justify-content: flex-start;
    // align-items: flex-start;
    padding-right: 10px;
  }
  &::-webkit-scrollbar {
    width: 0.59rem;
    height: 0.59rem;
    background-color: #eee;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aaa;
  }
}

.zoomImage__img_holder {
  object-position: center;
  object-fit: contain;
  img {
    box-shadow: 0px 4px 25px rgba(170, 154, 130, 0.5);
    object-position: center;
    object-fit: contain;
    @include mobile-xl {
      box-shadow: none;
    }
  }
}