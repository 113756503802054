@import '../../assets/styles/media';

.introContainer {
  height: 100%;
  display: flex;
  overflow: hidden;

  .introLeftContainer {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 70%;
    justify-content: center;
    @media (max-height: 705px) {
      justify-content: flex-start;
    }
    &::-webkit-scrollbar {
      width: 0.45rem;
      background-color: var(--color-scrollBackground);
      border-radius: 0.34rem;
      overflow: hidden;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-scrollBar);
      border-radius: 0.34rem;
    }
  }
  .introRightContainer {
    width: 30%;
    display: flex;
    .participantInfo {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;
      display: flex;
      width: 100%;
      .pIHeader {
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 1.25rem;
      }
      .noOfParticipants {
        font-weight: 700;
        font-size: 4rem;
        line-height: 5rem;
        margin: 1rem 0;
      }
      .pIFooter {
        font-weight: 500;
        font-size: 1rem;
        line-height: 1rem;
      }
    }
  }

  @include tablet-xl {
    overflow-y: unset;
  }
}
