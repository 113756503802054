@import '../../assets/styles/media';

.patientCases__content {
  width: 100%;
  height: auto;
  display: flex;
  color: #4f4f4f;
  .patientCases__information {
    background: linear-gradient(180deg, #c2dec4 0%, #a3d7e9 100%);
    padding: 1rem;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    .patientCases__details {
      display: flex;
      margin-bottom: 1rem;
      .patientCases__picture {
        margin-right: 0.75rem;
        .patientCases__img {
          object-position: center;
          object-fit: cover;
          width: 4rem;
          height: 5rem;
          border: 1px solid #4fb1c2;
          border-radius: 10px;
        }
      }
      .patientCases__info {
        width: calc(100% - 1rem);
        display: flex;
        align-items: center;
        .patientCases__person {
          .patientCases__name {
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 2rem;
          }
          .patientCases__gender {
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.5rem;
          }
          .patientCases__description {
            font-size: 0.85em;
            line-height: 1.25rem;
          }
        }
      }
      .patientCases__badge {
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
    .patientCases__AddDetails {
      background-color: var(--color-white);
      padding: 0.75rem;
      font-size: 1rem;
      line-height: 1.25rem;
      display: flex;
      flex-direction: column;
      height: auto;
      min-height: 10rem;

      .patientCases__history {
        margin-bottom: 0.5rem;
        display: flex;
        height: auto;
        flex-direction: column;
        .patientCases__historyTitle {
          font-weight: 800;
        }
        .patientCases__historyText {
          @include tablet-xxl {
            max-height: 3rem;
          }
          @include desktop-xxl {
            max-height: 7rem;
          }
          @include desktop-xl {
            max-height: 5rem;
          }
          font-weight: 500;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 0.4rem;
            background-color: var(--color-white);
            border-radius: 0.34rem;
            overflow: hidden;
          }

          &::-webkit-scrollbar-thumb {
            background-color: var(--color-gray8);
            border-radius: 0.34rem;
          }
        }
        p {
          margin-top: 5px;
        }
      }
    }
  }
}
