@import "../../assets/styles/media";

.background {
  position: fixed;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(128, 128, 128, 0.288);
}

.modal {
  height: 15rem;
  width: 30rem;
  // z-index: 13;
  border-radius: 0.675rem;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem;
  border: 2px solid #B7B7B7;
  border-radius: 1.3rem;

  @include mobile-xl{
    width: 90vw;
  }
}

.question {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
}

.actions { 
  display: flex;
  justify-content: space-between;
  & > button {
    width: 10.125rem;
  }

}