@import 'src/assets/styles/media';

.radiologyResults {
  padding: 0 1rem;
  height: 100%;

  @include tablet-xxl {
    padding-right: 0.69rem;
  }

  @include mobile-xl {
    padding-right: 0;
  }
}

.radiologyResults__image {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @include tablet-xl {
    padding-right: 0;
    flex-direction: row-reverse;
  }
}

.radiologyResults__pic {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  @include tablet-min-xl {
    height: 3rem;
    padding: 2px;
  }
  @include desktop-min-l {
    height: 5rem;
    padding: 5px;
  }
}

.radiology__video {
  margin: 5px 0;
}

.radiologyResults__img {
  width: auto;
  height: 100%;
  object-position: center;
  max-width: 120px;
}

.radiologyResults__vieWText {
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;

  @include tablet-xl {
    font-size: 1rem;
    margin-right: 0.77rem;
  }
}
.radiologyResults_table {
  height: 100%;
}
.radiologyResults_body {
  height: calc(100% - 3.62rem);
  overflow-y: auto;
  padding-right: 1rem;
  &::-webkit-scrollbar {
    width: 0.69rem;
    background-color: var(--color-scrollGrayBackground);
    border-radius: 0.34rem;
    overflow: hidden;
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--color-scrollBar);
    border-radius: 0.34rem;
  }
}
