@import 'src/assets/styles/media';

.human {
  height: auto;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  scrollbar-width: none;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0rem;
    background-color: transparent;
    border-radius: 0.34rem;
    overflow: hidden;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 0.34rem;
  }
}

.human__body {
  width: 100%;
  margin: auto;
  position: relative;
}

.human__dot {
  width: 100%;
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.human__part {
  width: 6.7%;
  position: absolute;

  &.head {
    width: 25.17%;
    left: 50%;
    transform: translateX(-50%);
    top: 1%;

    .human__dot {
      &::before {
        padding-top: 107%;
      }
    }
  }

  &.eye1 {
    right: 42%;
    top: 5%;
    width: 6.5%;

    &.Male {
      right: 45%;
    }

    .human__dot {
      &::before {
        padding-top: 100%;
      }
    }
  }

  &.eye2 {
    left: 42%;
    top: 5%;
    width: 6.5%;

    &.Male {
      left: 41%;
    }

    .human__dot {
      &::before {
        padding-top: 100%;
      }
    }
  }

  &.ear1 {
    right: 33%;
    top: 6.5%;
    width: 6.5%;

    &.Male {
      right: 37%;
    }

    .human__dot {
      &::before {
        padding-top: 100%;
      }
    }
  }

  &.ear2 {
    left: 33%;
    top: 6.5%;
    width: 6.5%;

    &.Male {
      left: 34%;
    }

    .human__dot {
      &::before {
        padding-top: 100%;
      }
    }
  }

  &.tongue {
    width: 12%;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;

    &.Male {
      left: 48%;
    }

    .human__dot {
      &::before {
        padding-top: 43%;
      }
    }
  }

  &.neck {
    width: 20%;
    left: 50%;
    transform: translateX(-50%);
    top: 15%;

    &.Male {
      width: 19%;
      top: 14%;
      left: 48%;
    }

    .human__dot {
      &::before {
        padding-top: 40%;
      }
    }
  }

  &.shoulder1 {
    width: 20%;
    left: 14%;
    top: 17%;

    &.Male {
      top: 16%;
    }

    .human__dot {
      &::before {
        padding-top: 48%;
      }
    }
  }

  &.shoulder2 {
    width: 20%;
    right: 14%;
    top: 17%;

    &.Male {
      top: 16%;
    }

    .human__dot {
      &::before {
        padding-top: 48%;
      }
    }
  }

  &.lungs {
    width: 44%;
    left: 50%;
    transform: translateX(-50%);
    top: 22%;

    .human__dot {
      &::before {
        padding-top: 34.84%;
      }
    }
  }

  &.chest1 {
    width: 17%;
    left: 32%;
    top: 22%;
    // @include tablet-xxl {
    //   top: 24%;
    // }

    &.Male {
      width: 21%;
      top: 17%;
      left: 28%;
    }

    .human__dot {
      &::before {
        padding-top: 100%;
      }
    }
  }

  &.chest2 {
    width: 17%;
    right: 32%;
    top: 22%;
    // @include tablet-xxl {
    //   top: 24%;
    // }

    &.Male {
      width: 21%;
      top: 17%;
      right: 28%;
    }

    .human__dot {
      &::before {
        padding-top: 100%;
      }
    }
  }

  &.back {
    width: 44%;
    left: 50%;
    transform: translateX(-50%);
    top: 20%;

    .human__dot {
      &::before {
        padding-top: 34.84%;
      }
    }
  }

  &.upperArm1 {
    width: 10.57%;
    left: 22%;
    top: 23%;
    transform: rotate(10deg);

    &.Male {
      top: 23%;
      left: 19%;
      transform: rotate(10deg);
    }

    .human__dot {
      &::before {
        padding-top: 231.82%;
      }
    }
  }

  &.upperArm2 {
    width: 10.57%;
    right: 22%;
    top: 23%;
    transform: rotate(-10deg);

    &.Male {
      top: 23%;
      transform: rotate(-10deg);
      right: 21%;
    }
    .human__dot {
      &::before {
        padding-top: 231.82%;
      }
    }
  }

  &.waist {
    width: 45.94%;
    right: 50%;
    transform: translateX(50%);
    top: 37%;
    &.Male {
      top: 34%;
    }
    .human__dot {
      &::before {
        padding-top: 25.84%;
      }
    }
  }

  &.lowerArm1 {
    width: 10.57%;
    left: 17.5%;
    top: 38%;
    transform: rotate(8deg);

    &.Male {
      top: 36%;
      left: 14%;
      transform: rotate(15deg);
    }

    .human__dot {
      &::before {
        padding-top: 231.82%;
      }
    }
  }

  &.lowerArm2 {
    width: 10.57%;
    right: 16.5%;
    top: 38%;
    transform: rotate(-8deg);

    &.Male {
      top: 36%;
      transform: rotate(-15deg);
      right: 14%;
    }

    .human__dot {
      &::before {
        padding-top: 231.82%;
      }
    }
  }

  &.abdomen {
    width: 38%;
    left: 50%;
    transform: translateX(-50%);
    top: 41%;

    // @include tablet-xxl {
    //   top: 45%;
    // }

    &.Male {
      top: 38%;
      left: 50%;
    }

    .human__dot {
      &::before {
        padding-top: 34.84%;
      }
    }
  }

  &.genitourinary1 {
    right: 29%;
    top: 39%;
    width: 6.5%;

    .human__dot {
      &::before {
        padding-top: 100%;
      }
    }
  }

  &.genitourinary2 {
    left: 29%;
    top: 39%;
    width: 6.5%;

    .human__dot {
      &::before {
        padding-top: 100%;
      }
    }
  }

  &.wrist1 {
    width: 14%;
    left: 13%;
    bottom: 47%;

    &.Male {
      width: 15%;
      bottom: 50%;
      left: 7%;
    }

    .human__dot {
      &::before {
        padding-top: 70%;
      }
    }
  }

  &.wrist2 {
    width: 14%;
    right: 13%;
    bottom: 47%;

    &.Male {
      width: 15%;
      bottom: 51%;
      right: 9%;
    }

    .human__dot {
      &::before {
        padding-top: 70%;
      }
    }
  }

  &.hand1 {
    width: 13%;
    left: 12%;
    top: 54%;

    // @include tablet-xxl {
    //   top: 56.5%;
    // }

    &.Male {
      width: 14.52%;
      bottom: 43%;
      left: 4%;
    }

    .human__dot {
      &::before {
        padding-top: 100%;
      }
    }
  }

  &.hand2 {
    width: 13%;
    right: 12%;
    top: 54%;

    // @include tablet-xxl {
    //   top: 56.5%;
    // }

    &.Male {
      width: 14.52%;
      bottom: 43%;
      right: 6%;
    }

    .human__dot {
      &::before {
        padding-top: 100%;
      }
    }
  }

  &.upperLeg1 {
    width: 14.57%;
    left: 30%;
    bottom: 32%;

    &.Male {
      left: 29%;
      bottom: 36%;
    }

    .human__dot {
      &::before {
        padding-top: 231.82%;
      }
    }
  }

  &.upperLeg2 {
    width: 14.57%;
    right: 33%;
    bottom: 32%;

    &.Male {
      bottom: 36%;
      right: 32%;
    }

    .human__dot {
      &::before {
        padding-top: 231.82%;
      }
    }
  }

  &.knee1 {
    width: 18%;
    left: 30%;
    bottom: 24%;

    &.Male {
      left: 28%;
      bottom: 28%;
    }

    .human__dot {
      &::before {
        padding-top: 100%;
      }
    }
  }

  &.knee2 {
    width: 18%;
    right: 31%;
    bottom: 24%;

    &.Male {
      right: 30%;
      bottom: 28%;
    }

    .human__dot {
      &::before {
        padding-top: 100%;
      }
    }
  }

  &.lowerLeg1 {
    width: 12.57%;
    left: 35%;
    bottom: 11%;
    transform: rotate(-8deg);

    &.Male {
      left: 27%;
      width: 15%;
      transform: rotate(0deg);
      bottom: 10%;
    }

    .human__dot {
      &::before {
        padding-top: 231.82%;
      }
    }
  }

  &.lowerLeg2 {
    width: 12.57%;
    right: 37%;
    bottom: 11%;
    transform: rotate(8deg);

    &.Male {
      right: 30%;
      width: 15%;
      transform: rotate(0deg);
      bottom: 10%;
    }

    .human__dot {
      &::before {
        padding-top: 231.82%;
      }
    }
  }

  &.ankle1 {
    width: 12%;
    left: 35%;
    bottom: 10%;

    &.Male {
      left: 28%;
      bottom: 11%;
    }

    .human__dot {
      &::before {
        padding-top: 100%;
      }
    }
  }

  &.ankle2 {
    width: 12%;
    right: 39%;
    bottom: 10%;

    &.Male {
      right: 31%;
      bottom: 11%;
    }

    .human__dot {
      &::before {
        padding-top: 100%;
      }
    }
  }

  &.foot1 {
    width: 17.13%;
    left: 30.5%;
    bottom: 3%;

    &.Male {
      left: 24%;
    }

    .human__dot {
      &::before {
        padding-top: 48.94%;
      }
    }
  }

  &.foot2 {
    width: 17.13%;
    right: 33.5%;
    bottom: 3%;

    &.Male {
      right: 27%;
    }

    .human__dot {
      &::before {
        padding-top: 48.94%;
      }
    }
  }
}
.human_img_container {
  @media (max-height: 800px) {
    &.x0 {
      height: 350px;
      width: 170px;
    }
  }
  @media (min-height: 800px) {
    &.x0 {
      height: 402px;
      width: 180px;
    }
  }
  &.x1 {
    height: 446px;
    width: 203px;
  }
  &.x2 {
    height: 490px;
    width: 223px;
  }
  &.x3 {
    height: 540px;
    width: 245px;
  }
  .human__svg {
    height: 100%;
    width: 100%;
  }
}

.human__label {
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.human__checkmark {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  background-color: var(--color-darkGreen);
  opacity: 0.6;

  &.pollingItem {
    background-color: var(--color-polling);
  }
}

.human__checkbox {
  display: none;

  &:checked + .human__checkmark {
    background-color: var(--color-darkGreen);
    opacity: 1;
  }

  &:checked + .pollingItem {
    border: 2px solid var(--color-white);
    background-color: var(--color-polling);
  }
}
