@import '../../../../assets/styles/media';
.tableViewSection {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.groupList {
  margin: 0;
  list-style-type: none;
//   display: flex;
//   flex-wrap: wrap;
  // width: 100%;
  // height: 100%;
  // flex-direction: column;
  overflow-y: auto;
  border-top: 0;
  padding: 1rem;
  word-break: break-word;
  @include mobile-xl {
    padding: 0.5rem;
  }
  &.labNoGroup {
    display: flex;
    flex-wrap: wrap;
    @include mobile-xl {
      flex-wrap: nowrap;
    }
  }
}

.tvHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.5rem;
  margin-bottom: 1rem;
  @include mobile-xl {
    margin-bottom: 0;
  }
  p {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 2rem;
    text-transform: uppercase;
    @include tablet-xxl {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  p::before,
  p::after {
    content: '';
    display: flex;
    width: 30%;
    height: 10px;
    background-color: #d9d9d9;
    @include mobile-xl {
      width: 10%;
    }
  }
}
.lab_sheet_body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.labTemplate {
    border: 0.5px solid var(--color-darkGreen);
    border-top: 0;
    height: calc(100% - 2.62rem);
    padding: 1rem;
    @include mobile-xl {
      padding: 0.5rem;
    }

    .groupList {
      height: calc(100% - 1rem);
    }
  }

  .lab_sheet_placeholder {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin: 0.5rem 0;
    @include mobile-xl {
      font-size: 0.95rem;
    }
  }

  .questionSet1__selectAll {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1rem;
    @include mobile-xl {
      margin-bottom: 0.25rem;
    }
  }

  .questionSet1__selectAll_groups {
    margin-right: 2.5rem;
    @include mobile-xl {
      margin-right: 0rem;
    }
  }

  .questionSet1__selectInner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.questionSet1__selectAll {
  margin-bottom: 0.5rem;
}
.tvContainer {
  height: calc(100% - 3.5rem);
  display: flex;
  flex-direction: column;
  .lab_sheet_header {
    display: flex;
    flex-direction: column;
    .lab_sheet_heading {
      display: flex;
      flex-direction: row;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      @include mobile-xl {
        font-size: 0.75rem;
        line-height: 1rem;
      }
      align-items: center;
      text-align: center;
      color: var(--color-white);
      .ls_header1 {
        flex: 70%;
        background-color: var(--color-darkGreen);
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
      }
      .ls_header2 {
        flex: 30%;
        padding: 0.25rem;
        background-color: #4f4f4f;
      }
    }
    .lab_sheet_head_body {
      display: flex;
      flex-direction: row;
      flex: 100%;
      min-height: 3rem;
      border: 0.5px solid var(--color-darkGreen);
      border-top: 0;
      .lab_sheet_patient_info {
        display: flex;
        width: 70%;
        justify-content: space-between;
        font-size: 0.9rem;
        line-height: 1rem;
        font-weight: 600;
        padding-right: 2rem;
        @include mobile-xl {
          font-size: 0.7rem;
          line-height: 1rem;
        }
        @include mobile-xl {
          padding-right: 0.5rem;
        }
        .lab_patient_details {
          display: flex;
          flex-direction: row;
          .lab_sheet_pi_holder {
            padding-right: 1rem;
          }
        }
        .lab_sheet_pi_holder {
          padding-left: 1rem;
          padding-top: 0.35rem;
          @include mobile-xl {
            padding-left: 0.25rem;
          }
          .lab_sheet_pi_header {
            margin-bottom: 0.1rem;
          }
          .lab_sheet_pi_content {
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.5rem;
            @include mobile-xl {
              font-size: 0.7rem;
              line-height: 1rem;
            }
          }
        }
      }
      .lab_sheet_physician_info {
        flex: 30%;
        display: flex;
        border-left: 1px solid var(--color-darkGreen);
        align-items: center;
        justify-content: center;
        font-weight: 700;
      }
    }
  }
}
