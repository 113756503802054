.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox__label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox__inner {
  border: 1px solid var(--color-darkGreen);
  border-radius: 1px;
  width: 1.68rem;
  height: 1.68rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;

  &.checked{
    background-color: var(--color-darkGreen);
  }
}

.isDisabled {
   cursor: default;
  .checkbox__title{
   color: var(--color-gray10);
  }
  .checkbox__inner {
   border: 1px solid var(--color-gray10);
  }
}

.checkbox__checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  flex-shrink: 0;
}

.checkbox__input {
  display: none;

  &:checked + .checkbox__checkmark {
    opacity: 1;
  }
}

.checkbox__title {
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: var(--color-fontBlack);
}

.dropdownRow__checkbox {
  width: 1.68rem;
  height: 1.68rem;
  border: 1px solid var(--color-darkGreen);
  border-radius: 1px;
  flex-shrink: 0;
}