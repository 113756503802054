.chatWrapper {
  margin: 1rem;
  display: flex;
  flex-direction: column;
}

.chatBox {
  display: flex;
  align-items: center;

  .chatDoctor_img {
    border-radius: 10px;
    margin: 0 0.9rem;
    width: 60px;
    height: 72px;
  }
  div {
    display: flex;
    border-radius: 4px;
    font-weight: 700;
    font-size: 0.85rem;
    line-height: 1.25rem;
    padding: 0.9rem;
    width: 60%;
  }
}
.chatQuestion {
  justify-content: right;
  margin-bottom: 1rem;

  .chatQuestionItem {
    background-color: var(--color-scrollBar);
    color: var(--color-white);
    position: relative;
    display: flex;
    justify-content: flex-end;
    text-align: end;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: -5px;
      border-bottom: 10px solid var(--color-scrollBar);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }
}

.chatAnswer {
  justify-content: left;
  .chatAnswerItem {
    background-color: var(--color-white);
    color: var(--color-fontBlack);
    position: relative;
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: -5px;
      border-bottom: 10px solid var(--color-white);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }
  .chatDoctor_img {
    border: 2.5px solid var(--color-highlightGreen);
  }
}
