.alert {
  position: fixed;
  top: -300px;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .5s;
  border-radius: 0.375rem;
  color: white;
  padding: 1rem 1.4rem;
  width: max-content;
  z-index: 999;

  & > div {
    font-size: 1.125rem;
    font-weight: 700;
  }
}

.alertError {
  background-color: rgb(255, 66, 66);
}

.alertSuccess {
  background-color: var(--color-green);
}

.alertWarning {
  background-color: rgb(255, 204, 0);
}

.active {
  top: 20px;
}