@import 'src/assets/styles/media';

.p_container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  .p_left_arrow,
  .p_right_arrow {
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 2.5rem;
    cursor: pointer;
    &:hover {
      transform: scale(1.25, 1.25);
    }
    &.p_right_visibilty,
    &.p_left_visibilty {
      visibility: hidden;
    }
    &.p_right_disabled,
    &.p_left_disabled {
      &:hover {
        transform: scale(1, 1);
      }
      cursor: auto;
    }
  }
  .p_holder {
    width: calc(100% - 5rem);
    height: 100%;
    display: flex;
    @include mobile-xl{
      width: calc(100% - 2rem);
    }
    .image_text_container {
      width: 100%;
      height: calc(100% - 1rem);
      align-items: center;
      justify-content: center;
      display: flex;
    }
    .image_container {
      width: 100%;
      height: calc(100% - 1rem);
      display: flex;
      flex-direction: column;
      padding: 1rem;
      @include mobile-xl {
        padding: 0.5rem;
      }
      .image_holder {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        @include mobile-xl {
          overflow-x: auto;
          overflow-y: hidden;
        }
        img {
          cursor: zoom-in;
          max-width: 100%;
          max-height: 100%;
          // image-rendering: pixelated;
          object-fit: contain;
          object-position: center;
          box-shadow: 0px 4px 25px rgb(170 154 130 / 80%);

          @include mobile-xl {
            // height: 100%;
            width: auto;
            // width: calc(160% - 1rem);
            // width: 100%;
            // height: auto;
            object-fit: contain;
          }
        }
        &.zoom_image {
          transform: scale(1, 1);
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          overflow: auto;
          img {
            cursor: zoom-out;
            width: 100%;
            height: 100%;

            @include mobile-xl {
             width: auto;
             height: auto;
            }
          }

          @include mobile-xl {
            height: calc(100% - 10px);
            width: calc(100% - 10px);
            left: 5px;
          }
        }
      }
      .image_header {
        font-size: 0.65rem;
        line-height: 1.25rem;
        color: #bbbecb;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
