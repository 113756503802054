@import '../../../../../../assets/styles/media';

.dragRow,
.cardRow {
  margin-bottom: 0.85rem;
  height: 3rem;
  display: flex;
  align-items: stretch;
  background-color: var(--color-scrollGrayBackground);
  z-index: 10;

  &.drag {
    justify-content: space-between;
    background-color: var(--color-scrollGrayBackground);
    cursor: grab;
    overflow: hidden;
  }
}
.cardRow {
  border-right: 2px solid var(--color-highlightGreen);
}

.pollingItem {
  .cardRow__number {
    background-color: var(--color-polling);
  }
  .cardRow__text {
    color: var(--color-polling);
  }
}

.cardRow__number {
  margin-right: 1rem;
  width: 2.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: var(--color-white);
  background-color: var(--color-fontBlack);
  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile-xl {
    margin: 0 1rem;
  }
}

.cardRow__info {
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.cardRow__drag {
  width: 10rem;
  height: 3.5rem;
  vertical-align: middle;
  z-index: 1000;
}

.cardRow__drag__icon {
  width: 3rem;
  height: 100%;
  margin-right: 5px;
  &:hover {
    transform: scale(1.12, 1.12);
  }
}

.cardRow__drag__handle {
  width: 3rem;
  height: 100%;
}
.card_disabled {
  cursor: auto;
  &:hover {
    transform: none;
  }
  rect {
    fill: var(--color-gray8);
  }
}

.cardRow__drag__handle {
  padding-bottom: 0.5rem;
  padding-left: 3px;
  padding-right: 5px;
}

.cardRow__text {
  padding: 0.31rem;
  font-size: 1.125rem;
  font-weight: 700;
  // color: var(--color-gray2);
  margin: 0.5rem 0;
}
