@import "../../../../assets/styles/media";

.questionSet2 {
  padding: 1.69rem;
  overflow-y: auto;

  @include desktop-l {
    padding-left: 0;
  }

  @include mobile-xl {
    padding-right: 0;
  }
}

.questionSet2__list {
  margin: 0;
  padding: 0 0 0.1rem 0;
  list-style-type: none;
  position: relative;
  transition: 0.3s;
}
