@import '../../assets/styles/media';

.doctorContainer {
  margin-top: 1.25rem;
  width: 100%;
  color: #4f4f4f;
  background: linear-gradient(
    180deg,
    rgba(162, 214, 233, 0.75) 0%,
    rgba(130, 208, 237, 0.75) 100%
  );
  padding: 0.85rem;
  display: flex;
  .doctorPicture {
    margin-right: 0.75rem;
    .doctorImage {
      object-position: center;
      object-fit: cover;
      width: 4rem;
      height: 5rem;
      border: 1px solid #4fb1c2;
      border-radius: 10px;
    }
  }
  .doctorInfo {
    width: calc(100% - 5rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    .doctorName {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2rem;
    }
    .doctorLocation {
      font-weight: 600;
      font-size: 0.9rem;
      line-height: 1.5rem;
    }
  }
  .doctorBadge {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
