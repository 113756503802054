@import '../../assets/styles/media';

*{
  scrollbar-color: var(--color-scrollBar) var(--color-scrollGrayBackground);
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
}
::-webkit-scrollbar {
  width: 0.49rem;
  height: 0.49rem;
  background-color: var(--color-scrollGrayBackground);
  border-radius: 0.34rem;
  overflow: hidden;
  background-clip: padding-box;
  -webkit-overflow-scrolling: touch;
  -webkit-appearance: none;

  @include tablet-xl {
    width: 0.45rem;
    height: 0.45rem;
  }

  @include mobile-xl {
    width: 0.25rem;
    height: 4px;
  }
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-scrollBar);
  border-radius: 0.34rem;
}

html {
  box-sizing: border-box;
  // font-size: 13px;
  font-family: 'Poppins', sans-serif;
  color: var(--color-app-font);
  scrollbar-color: var(--color-scrollBar) var(--color-scrollGrayBackground);
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
  
  @include tablet-xxl {
    font-size: 13px;
  }

  @include tablet-xl {
    font-size: 10px;
  }

  @include mobile-xl {
    font-size: 11px;
  }

  @include tablet-xl {
    font-size: 10px;
  }

  @include desktop-xxl {
    font-size: 16px;
  }

  @include desktop-xl {
    font-size: 16px;
  }

  @include desktop-l {
    font-size: 14px;
  }

  @include desktop-m {
    font-size: 14px;
  }
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

body {
  margin: 0;
}

.hidden {
  display: none;
}

.img-container {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

.no-scroll {
  overflow: hidden;
}

.pollingItem {
  color: var(--color-polling);
}

.paragraph {
  margin: 0.3rem 0;
}

.header_paragraph {
  margin: 0;
  line-height: 1.8rem;
}

// React Responsice Carousel Custom Sytles

.carousel-root {
  width: inherit;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #000;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #000;
}

.carousel.carousel-slider {
  overflow: visible;
}

.carousel .control-dots {
  position: absolute;
  bottom: -30px;
  @include mobile-xl {
    bottom: 10px;
  }
}

.carousel .control-dots .dot {
  background: #ffffff;
  border: 2px solid var(--color-darkblue);
  width: 10px;
  height: 10px;
  box-shadow: none;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background: var(--color-darkblue);
}

.carousel .slide img {
  width: fit-content;
}

// @media screen and (min-width: 1301px) {
//   .carousel .slide img {
//     max-width: 600px;
//   }
// }

// @media screen and (max-width: 1300px) {
//   .carousel .slide img {
//     max-width: 600px;
//   }
// }

// @media screen and (max-width: 1200px) {
//   .carousel .slide img {
//     max-width: 500px;
//   }
// }

// @media screen and (max-width: 1000px) {
//   .carousel .slide img {
//     max-width: 400px;
//   }
// }

// @media screen and (max-width: 700px) {
//   .carousel .slide img {
//     max-width: 300px;
//   }
// }

// @media screen and (max-width: 300px) {
//   .carousel .slide img {
//     max-width: 200px;
//   }
// }
