@import 'src/assets/styles/media';

.dropdownRow__item {
  // margin-bottom: 1.5rem;
  margin-right: 0.25rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  min-height: 1.5rem;
  word-wrap: break-word;
  word-break: break-word;
  page-break-inside: avoid;
  cursor: pointer;
  &.treatment {
    width: 35rem;
  }
  .dropdownRow__checkbox {
    width: 1.68rem;
    height: 1.5rem;
    border: 1px solid var(--color-darkGreen);
    border-radius: 1px;
    flex-shrink: 0;
    margin-bottom: 0.5rem;
  }

  .dropdownRow__text {
    // margin-left: 1rem;
    padding-left: 0.7rem;
    padding-right: 1rem;
    min-height: 1.5rem;
    padding-bottom: 0.5rem;
  }

  &.active {
    .dropdownRow__checkbox {
      background-color: var(--color-darkGreen);
    }
  }
  &.pollingItem {
    .dropdownRow__text {
      color: var(--color-polling);
    }
  }

  @include mobile-xl {
    padding-left: 1rem;
  }
}
