@import 'src/assets/styles/media';

.photoPopup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
}

.photoPopup__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;
  height: 90vh;
  position: relative;
  padding: 0.5rem;
  padding-bottom: 1rem;
  padding-right: 1.25rem;
  border: 8px solid var(--color-darkblue);
  background-color: var(--color-white);
  border-radius: 0.75rem;
  @include desktop-l {
    margin: 1.875rem;
    border: 6px solid var(--color-darkblue);
  }

  @include mobile-xl {
    height: 80vh;
  }
}

.photoPopup__pic {
  width: 100%;
  height: 100%;
  padding: 0.81rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  scrollbar-color: #aaa #eee;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
  @include tablet-xl {
    justify-content: flex-start;
    overflow: auto;
  }
  @include mobile-xl {
    justify-content: flex-start;
    overflow: auto;
  }
  &::-webkit-scrollbar {
    width: 0.59rem;
    height: 0.59rem;
    background-color: #eee;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aaa;
  }
}

.photoPopup__img_holder {
  object-position: center;
  object-fit: contain;
  img {
    box-shadow: 0px 4px 25px rgba(170, 154, 130, 0.5);
    object-position: center;
    object-fit: contain;
  }
}

.photoPopupClose {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.photoPopup__close {
  position: absolute;
  top: -1.845rem;
  right: -1.845rem;
  padding: 0.81rem;
  width: 3.69rem;
  height: 3.69rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--color-darkblue);
  cursor: pointer;
  z-index: 15;

  @include desktop-l {
    top: -1.47rem;
    right: -1.47rem;
    width: 2.69rem;
    height: 2.69rem;
  }

  @include mobile-xl {
    top: -1.2rem;
    right: -1.3rem;
  }
}

.photoPopup__closeSvg {
  fill: var(--color-white);
  width: 100%;
  height: 100%;
  object-position: center;
}

.physical_settings {
    height: 3.5rem;
    z-index: 100;
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 10px;
    justify-content: center;
    align-items: center;

    .ps_label {
      font-weight: 800;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    .zoom_disabled {
      filter: invert(100%) sepia(5%) saturate(10%) hue-rotate(221deg) brightness(150%) contrast(73%);

      &.ps_zminus:hover,
      &.ps_zplus:hover {
        transform: none;
        cursor: auto;
      }
    }

    .ps_options {
      display: flex;
      flex-direction: row-reverse;

      .ps_zminus,
      .ps_zplus {
        cursor: pointer;
        height: 1.75rem;
      }

      img:hover {
        transform: scale(1.1, 1.1);
        transform: scale3d(1.1, 1.1);
      }

      .ps_zplus {
        margin-left: 0.75rem;
      }
    }
  }