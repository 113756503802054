@import '../../assets/styles/media';

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mobile-xl {
    padding: 0;
  }
}

.test {
  background-color: lightgreen;
}

.headerStart {
  display: flex;
  align-items: center;
  justify-content: center;

  @include tablet-xxl {
    // margin-left: 3.16rem;
  }

  @include mobile-xl {
    // margin-left: 1.5rem;
  }
}

.headerStart__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.25rem;
  min-width: 12.12rem;
  height: 3.62rem;
  font-size: 1.25rem;
  font-weight: 800;
  text-align: center;
  border: 2px solid var(--color-darkblue);
  border-radius: 5px;
  color: var(--color-darkblue);
  background-color: transparent;
  cursor: pointer;

  @include tablet-xxl {
    min-width: 7.57rem;
    height: 2.26rem;
    font-size: 1rem;
  }
}

.headerInfo {
  display: flex;
  align-items: center;
  color: var(--color-fontBlack);

  .headerStart {
    width: 20rem;

    @include tablet-xxl {
      width: 16rem;
    }

    @include mobile-xl {
      width: auto;
    }

    justify-content: flex-start;
  }

  @include mobile-xl {
    .headerStart {
      position: absolute;
      top: 0;
      left: calc(50% - 3rem);

      .headerStart__button {
        border-radius: 0;
      }
    }
  }
}

.headerEventInfo {
  @include tablet-xl {
    .headerText__eventDetails,
    .headerText__eventName {
      font-size: 0.85rem;
      line-height: 1.25rem;
      overflow: ellipsis;
    }
  }
  @include mobile-xl {
    bottom: 5px;
    position: absolute;
    // top: 2.5rem;
    left: 0px;
    width: 100%;
    text-align: center;
    margin: 0;

    .headerText {
      margin-right: 0rem;
      // flex-direction: row;
      // gap: 0.25rem;
    }

    .headerText__eventDetails,
    .headerText__eventName {
      font-size: 0.75rem;
      line-height: 1rem;
      overflow: ellipsis;
    }
  }
}

.headerText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 1rem;
}

.headerText__eventDetails,
.headerText__eventName {
  font-size: 1rem;
  line-height: 1.25rem;
  margin: 0;
  text-transform: capitalize;
}

.headerText__eventName {
  font-weight: 700;
}

.headerPharmaceutical__icon,
.headerLogo__icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 11rem;
  height: 2.5rem;

  @include mobile-xl {
    width: 6rem;
    height: 3rem;
  }

  @include tablet-xxl {
    width: 7rem;
    height: 2.5rem;
  }
}

.header_custom_logo,
.header_custom_logo_bi {
  display: flex;
  width: 15rem;
  .welcome_lilly_img {
    height: 3rem;
    width: 7rem;
    margin-top: 0.5rem;
  }
  @include mobile-xl {
    display: flex;
    width: 10rem;
    justify-content: flex-start;
    margin-left: 5px;
    .welcome_lilly_img,
    .welcome__img {
      width: 5rem;
      height: 3rem;
      margin-right: 5px;
    }
    .welcome_lilly_img {
      width: 3.5rem;
      height: 2rem;
      margin-top: 0.5rem;
    }
  }
}
.header_custom_logo_bi {
  .welcome__img {
    max-width: 100%;
    // min-height: 100%;
    height: auto;
    width: auto;
  }
  @include mobile-xl {
    justify-content: flex-end;
  }
}

.welcome__img {
  width: 100%;
  height: 100%;
  object-position: center;
}
