.anamnesis {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.anamesis_wrapper {
  display: flex;
  height: 100%;
  .anamesis_chatBox {
    flex: 50%;
    width: 100%;
    background: var(--color-linearGreen);
    border: 1px solid var(--color-scrollBar);
    border-radius: 4px;
    padding: 0.9rem;
    .anamesis_chatBox_holder {
      height: 100%;
      overflow-y: auto;
      .noSelectedChat{
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
      &::-webkit-scrollbar {
        width: 0.5rem;
        background-color: var(--color-white);
        border-radius: 0.34rem;
        overflow: hidden;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--color-scrollBar);
        border-radius: 0.34rem;
      }
    }
  }
  .anamnesis__list {
    height: 100%;
    margin: 0;
    padding: 0.9rem;
    flex: 50%;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0.5rem;
      background-color: var(--color-scrollGrayBackground);
      border-radius: 0.34rem;
      overflow: hidden;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-scrollBar);
      border-radius: 0.34rem;
    }
  }
}
