@import '../../assets/styles/media';

.background {
  position: fixed;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(128, 128, 128, 0.288);
}

.modal {
  max-height: 80%;
  width: 75%;
  margin: 5rem;
  z-index: 13;
  border-radius: 0.675rem;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  border: 3.5px solid var(--color-darkGreen);
  border-radius: 1.3rem;

  @include mobile-xl {
    width: 90vw;
  }
}
.heading {
  padding: 0.75rem 1rem 0.75rem 2.25rem;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  background: linear-gradient(180deg, #c2dec4 0%, #a3d7e9 100%);
  border-radius: 10px 10px 0px 0px;
}

.heading__present {
  display: flex;
  flex-direction: column;
}

.heading__title {
  display: flex;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2rem;

  @include tablet-xl {
    font-size: 1.75rem;
    margin-top: 10px;
  }
}

.heading__subtitle {
  font-size: 1.12rem;
  font-weight: 600;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;

  &:first-letter {
    text-transform: uppercase;
  }
  @include tablet-xl {
    font-size: 1.1rem;
  }

  @include mobile-xl {
    line-height: 1.5rem;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pollNoData {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 1rem;
  min-height: 10rem;
  align-items: center;
}

.pollTimerBody {
  display: flex;
  width: 100%;
  height: calc(100% - 5rem - 200px);
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .pollTimer {
    margin: 3.5rem 0;
    font-size: 2rem;
    color: var(--color-polling);
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    .pollTimerLabel {
      color: var(--color-fontBlack);
      font-size: 1rem;
      margin-top: 0.5rem;
    }
    .pollCount {
      color: var(--color-darkGreen);
      padding-top: 3rem;
    }
  }

  .showPollBtnHolder {
    display: flex;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .showPollBtn {
      width: max-content;
      padding: 1rem;
      font-weight: 600;
      background-color: var(--color-darkGreen);
      color: white;
      cursor: pointer;
      border-radius: 0.5rem;
      font-size: 1rem;
    }
  }
}

.pollBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  height: calc(100% - 5rem - 200px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 1rem;

  .pollInfo {
    display: flex;
    flex-direction: row;

    box-sizing: border-box;
    font-weight: 500;
    width: 75%;
    color: var(--color-fontBlack);
    margin-top: 5px;
    padding: 0.5rem;
    .pollDetails {
      width: 40%;
      display: flex;
      flex-direction: row;
      .pollVotes {
        width: 100px;
      }
      .pollPerct {
        margin-left: 1rem;
      }
    }

    .pollItem {
      width: 60%;
      display: flex;
      flex-direction: column;
    }
    .pollPerct {
      text-align: center;
    }
    &.pollVerdict {
      font-weight: 700;
      background: var(--color-pollVerdict);
      color: var(--color-polling);
    }
    &.pollNextOrder {
      font-weight: 700;
      color: var(--color-polling);
    }
  }

  .pollRankContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    .pollRankMenu {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      flex-direction: row;
      input {
        margin-left: 1rem;
        margin-right: 0.25rem;
        margin-bottom: 0.75rem;
      }
    }
    .pollRankBody {
      width: 100%;
      display: flex;
      flex-direction: column;
      .pollRankHeader {
        color: var(--color-fontBlack);
        font-weight: 600;
        display: flex;
        width: 100%;
        flex-direction: row;
        padding: 8px;
        background-color: rgba(217, 217, 217, 1);
        .pollAnswer {
          width: 55%;
        }
        .pollRank {
          width: 90px;
          text-align: center;
        }
      }
      .pollRankHolder {
        color: var(--color-polling);
        font-weight: 400;
        display: flex;
        flex-direction: column;
        width: 100%;
        .pollAnswerContainer {
          margin: 0.75rem 0.35rem;
          width: 100%;
          font-size: 1rem;
          display: flex;
          flex-direction: row;
          .pollAnswer {
            width: 55%;
            overflow: hidden;
            word-wrap: break-word;
            display: flex;
            flex-direction: row;
            font-weight: 700;
            color: var(--color-polling);
          }
        }
        .pollRank {
          width: 90px;
          text-align: center;
          color: var(--color-fontBlack);
        }
        .pollVerdict {
          font-weight: 700;
          color: var(--color-polling);
        }
        .pollSecondMax {
          font-weight: 600;
          color: var(--color-polling);
        }
      }
    }
  }
}
