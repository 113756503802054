@import "src/assets/styles/media";

.patientCases {
  padding: 4.44rem 0 0 5.69rem;
  height: 100%;
  display: flex;
  flex-direction: column;

  @include mobile-xl {
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.patientCases__title {
  margin-bottom: 3.88rem;
  font-size: 2.12rem;
  font-weight: 700;
  color: var(--color-black);
  text-align: left;

  @include mobile-xl {
    margin-bottom: 1.88rem;
    font-size: 1.8rem;
  }
}

.patientCasesCard__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  // max-height: calc(100% - 20.99rem);
  flex: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.69rem;
    background-color: var(--color-scrollBackground);
    border-radius: 0.34rem;
    overflow: hidden;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-scrollBar);
    border-radius: 0.34rem;
  }

  @include mobile-xl {
    max-height: 100%;
    overflow-y: unset;
  }
}

.patientCasesCard__item {
  margin-bottom: 1.19rem;
}

