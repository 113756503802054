@import "src/assets/styles/media";

.anamnesisDropdown__list {
  width: 100%;
  margin: 0;
  padding: 1.75rem 0.94rem 0 1.75rem;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @include mobile-xl {
    padding: 1rem 0.5rem 0;
  }
}

.anamnesisDropdown__item {
  display: flex;
  width: 100%;
  min-height: 2.5rem;
  overflow: hidden;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
}

.anamnesisDropdown__question {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--color-fontBlack);
  padding: 5px;
  margin-top: 5px;


  @include mobile-xl {
    padding-left: 1.81rem;
    font-size: 1rem;
    line-height: 1.2rem;
  }

  .anamesisPoll_Enabled{
    color: var(--color-polling);
  }
}

.anamesisSelected_Question{
  color: var(--color-white);
  background-color: var(--color-darkGreen);
}